import PropTypes from "prop-types";
import React from "react";
import { withPrefix } from "gatsby";

const Redirect = ({ to = "/" }) => {
  if (typeof window !== "undefined") {
    window.location.href = withPrefix(to);
    const html = {
      _html: `
        <style>
            html, body {
                display: none;
            }
        </style>
      `,
    };

    return <div dangerouslySetInnerHTML={html} />;
  }

  return null;
};

Redirect.propTypes = {
  to: PropTypes.string,
};

export default Redirect;
